import { motion } from 'framer-motion';
import Link from 'next/link';
import React, { FC, useContext } from 'react';
import truncate from '../../../helpers/truncate';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import { CategoryType } from '../../../models/category-type';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import ProductImage from '../../atoms/product-image/product-image';
import TextLink from '../../atoms/text-link/text-link';

interface Props extends CategoryType {
  readonly animate?: boolean;
  readonly className?: string;
  readonly isActive?: boolean;
  readonly isPanel?: boolean;
  readonly index: number;
}

const CategoryCard: FC<Props> = ({
  animate,
  className,
  description,
  image,
  index,
  isActive,
  isPanel,
  name,
  numberOfProducts,
  url,
}: Props) => {
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);
  let backgroundColor = 'bg-light-blue';
  if (index % 3 === 1) {
    backgroundColor = 'bg-grey-50';
  } else if (index % 3 === 2) {
    backgroundColor = 'bg-floral';
  }

  const variants = {
    isActive: { opacity: 1 },
    notActive: { opacity: 0 },
  };

  const transition = {
    damping: 18,
    delay: 0.1,
    duration: 0.5,
    type: 'spring',
  };

  const panelClassNames = isPanel
    ? 'flex flex-row md:flex-col md:text-center p-4 lg:p-6'
    : 'flex flex-col text-center p-4 lg:p-6';

  return (
    <div
      className={
        `${className} ${backgroundColor} ${panelClassNames} ` +
        'h-full items-center justify-between rounded-xl overflow-hidden'
      }
    >
      {image?.url && (
        <motion.div
          animate={!animate || (animate && isActive) ? 'isActive' : 'notActive'}
          className="relative md:p-6 lg:p-2 lg:mb-4 w-4/12 md:w-full"
          transition={transition}
          variants={variants}
        >
          <Link href={url} passHref>
            <a href={url} target="_self">
              <ProductImage alt={image.altText} src={image.url} />
            </a>
          </Link>
        </motion.div>
      )}

      <motion.div
        animate={!animate || (animate && isActive) ? 'isActive' : 'notActive'}
        className={`
          ${
            isPanel
              ? 'w-8/12 md:w-auto items-start md:items-center pl-4 md:pl-0'
              : 'items-center'
          }
          flex-grow flex flex-col overflow-hidden
        `}
        transition={transition}
        variants={variants}
      >
        <span className="font-bold mb-2 text-lg">{name}</span>

        <div className="flex-grow flex flex-col justify-end">
          {description && (
            <p className="mb-3 text-grey-600">{truncate(description)}</p>
          )}

          <TextLink
            className={isPanel ? 'md:mx-auto' : 'mx-auto'}
            name={`See ${numberOfProducts} product${
              numberOfProducts > 1 ? 's' : ''
            }`}
            showIcon
            onClick={() => {
              pushToDataLayer(GtmEventNameEnum.CategoryCard, {
                cardTitle: name,
              });
            }}
            target="_self"
            url={url}
          />
        </div>
      </motion.div>
    </div>
  );
};

CategoryCard.defaultProps = {
  animate: false,
  className: '',
  isActive: false,
  isPanel: false,
};

export default CategoryCard;
