import React, { FC } from 'react';
import { SwiperSlide } from 'swiper/react';
import useIsBreakpoint from '../../../hooks/use-is-breakpoint/use-is-breakpoint';
import BreakpointEnum from '../../../models/breakpoint-enum';
import { CategoriesCarouselType } from '../../../models/categories-carousel-type';
import { CategoryType } from '../../../models/category-type';
import Carousel from '../../molecules/carousel/carousel';
import CategoryCard from '../../molecules/category-card/category-card';

const CategoriesCarousel: FC<CategoriesCarouselType> = ({
  heading,
  selectedCategories,
}: CategoriesCarouselType) => {
  const breakpoint = useIsBreakpoint();
  const breakpoints = {
    0: {
      centeredSlides: true,
      slidesPerGroup: 1,
      slidesPerView: 1.2,
    },
    641: {
      centeredSlides: false,
      slidesPerGroup: 2,
      slidesPerView: 2,
    },
    769: {
      slidesPerGroup: 3,
      slidesPerView: 3,
    },
    1025: {
      slidesPerGroup: 4,
      slidesPerView: 4,
    },
  };

  return (
    <div className="py-component-spacing-mobile lg:py-component-spacing">
      <Carousel
        breakpoints={breakpoints}
        heading={heading}
        numberOfSlides={selectedCategories.length}
        uniqueId="categories"
      >
        {selectedCategories.map((category: CategoryType, index: number) => (
          <SwiperSlide key={category.name}>
            {({ isActive, isVisible }) => (
              <CategoryCard
                {...category}
                animate
                index={index}
                isActive={
                  breakpoint === BreakpointEnum.sm ? isActive : isVisible
                }
              />
            )}
          </SwiperSlide>
        ))}
      </Carousel>
    </div>
  );
};

export default CategoriesCarousel;
